const firebaseConfig = {
  apiKey: "AIzaSyC7VuyQikJjQUmBqeKIMts73zZp8VUAKn0",
  authDomain: "ftwv2-93506.firebaseapp.com",
  // authDomain: "chatftw.com",
  projectId: "ftwv2-93506",
  storageBucket: "ftwv2-93506.appspot.com",
  messagingSenderId: "248677153555",
  appId: "1:248677153555:web:476c3dd99f546c2e76f8e6",
  measurementId: "G-P6ZQW61ZHP",
};
export default firebaseConfig;

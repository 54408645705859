import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import getRandomAvatar from "../utils/avatars";
import router from "@/router";
import { sports } from "@/components/svgs";
import axios from "axios";
// import { mdiMessage } from "@mdi/js";

export const useMainStore = defineStore("main", {
  state: () => ({
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: getRandomAvatar(),
    isAuth: false,
    chat_access: false,
    roulette_access: false,
    userIp: null,
    deviceType: null,
    token: null,
    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,
    backgroundImageIndex: 0,
    questions: [],
    sports: [],
    notifications: [],

    chats: [
      // {
      // id: "1",
      // name: "1",
      // lastAccessed: 0,
      // likeCount: 0,
      // dislikeCount: 0,
      // averageResponseTime: 0,
      // totalConversationDuration: 0,
      // errorRate: 0,
      // messages: [],
      // },
    ],
    upcoming: [],
    express: [],
    roi: [],
    selectedChat: {
      id: uuidv4(),
      name: "New chat",
      lastAccessed: new Date().getTime(),
      likeCount: 0,
      dislikeCount: 0,
      averageResponseTime: 0,
      totalConversationDuration: 0,
      errorRate: 0,
      messages: [],
    },
    loadingMessages: {
      preloads: [
        "Crunching the numbers for your question! Let's chat about the future. I'm constantly evolving, aiming for more precise predictions, richer analyses, and personalized experiences. Your engagement shapes my growth, so keep the questions coming! Answer incoming!",
        "Excited about your question! While I work on it, know that I specialize in sports events simulation, predictions, analyses, and up-to-date info on all things sports. Whatever your curiosity, I'm here for you! Your answer is just a moment away!",
        "Processing your question now! Meanwhile, here's a bit about how I work. I use data points like player stats, weather conditions, historical patterns, injury reports, and more. Comprehensive and accurate, that's my aim. Stay tuned, your answer is almost here!",
        "I'm racing through information for you! Ever hear about the guy who bet £50 on Leicester City to win the Premier League at 5000-to-1 odds? He cashed out for £72,500, missing out on £250,000 when they won! Nervous? I'm not! Your answer is on its way!",
        "Thanks for your question! As I gather the details, did you know the longest tennis match ever lasted 11 hours and 5 minutes? John Isner vs Nicolas Mahut at Wimbledon in 2010, spread over three days! Don't worry; I'll have your answer much quicker!",
        "Hang tight! I'm diving into the data. I excel at making sports predictions, using the latest stats and trends. Ever wondered who might win the big game? That's my specialty. I'll have your answer ready in a flash!",
        "Thanks for the question! While I gather your answer, let me share that I can simulate upcoming sports events. Curious about how a match might unfold? I can break it down for you. Stay tuned; your information is on the way!",
        "Glad you reached out! As I search for your answer, did you know I can create highlights of simulated events? Want to see key moments of a hypothetical game? I've got you covered! Your answer is almost ready.",
        "Working on your question now! Want to stump your friends with sports trivia? I can create challenging questions for your social media content or quiz night. Keep challenging me, and your answer will be ready soon!",
        "I'm on it! Need details for upcoming events? I can provide schedules, players, weather conditions, and more. Planning your game day has never been easier. Hang on; your answer is coming up!",
        "Thanks for your question! Ever wondered who would win between historical teams or fighters? I can simulate hypothetical events and predict outcomes. Imagine the classics, reimagined. Your answer will be here shortly!",
        "Excited to answer your question! I'm also quite handy at weighing in on the Greatest Of All Time debates. Need stats to back your argument? I've got you! Stay with me; your answer is on its way.",
        "Your question's in good hands! Want to know why a specific sports event is a must-watch? I can explain the significance and make any game more interesting. Grab some popcorn; your answer is coming right up!",
        "Appreciate the question! I'm here to make sports more engaging for you. Want predictions tailored to your favorite teams or personalized insights? That's my jam. Your answer will be here soon!",
        "I'm thrilled about your question! From predictions to simulations, trivia creation to event details, historical match-ups to GOAT debates, I'm your one-stop AI for all things sports. Keep those questions coming; your answer is almost here!",
      ],
      priority: [],
    },
    expiresAt: null,
  }),
  actions: {
    setUser(payload) {
      let _paq = (window._paq = window._paq || []);
      _paq.push(["setUserId", payload.email]);
      if (payload.name) {
        this.userName = payload.name;
      }
      if (payload.email) {
        this.userEmail = payload.email;
      }
      if (payload.avatar) {
        this.userAvatar = payload.avatar;
      }
      if (payload.isAuth) {
        this.isAuth = payload.isAuth;
      }
      if (payload.token) {
        this.token = payload.token;
      }
    },
    async checkChatAccess() {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/check_chat`,
          null,
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        );
        this.chat_access = response.data.chat_access;
        console.log("chat access", this.chat_access);
      } catch (error) {
        console.error(error);
      }
    },
    async buyChatAccess() {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/buy_chat`,
          null,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        );
        console.log("access:", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getName() {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/get_name`,
          null,
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        );
        console.log("name:", response.data);
        this.userName = response.data.name;
      } catch (error) {
        console.log(error);
      }
    },
    async changeName(name) {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/change_name`,
          {
            display_name: name,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        );
        this.userName = name;
        return response.data.message;
      } catch (error) {
        console.log(error);
      }
    },
    setBackgroundImage(imageUrl) {
      this.backgroundImage = imageUrl;
    },
    createNewChat(id) {
      const date = new Date();
      const newChat = {
        id: id || uuidv4(),
        name: "New chat",
        lastAccessed: date,
        likeCount: 0,
        dislikeCount: 0,
        averageResponseTime: 0,
        totalConversationDuration: 0,
        errorRate: 0,
        messages: [],
        newChat: true,
        selected: true,
      };
      // this.chats.push({
      //   name: newChat.name,
      //   id: newChat.id,
      //   lastAccessed: date,
      // });
      this.selectedChat = newChat;
      if (this.chats[this.chats.length - 1]?.newChat) {
        this.chats.pop();
      }
      this.chats.push(this.selectedChat);
      console.log("chat has been created");
    },
    async saveChat() {
      console.log("save");
      const chat = this.selectedChat;
      if (this.selectedChat?.newChat) {
        this.selectedChat.newChat = false;
        this.chats[this.chats.length - 1].newChat = false;
      }
      try {
        const payload = {
          uuid: chat.id,
          chat_name: chat.name,
          likeCount: chat.likeCount,
          dislikeCount: chat.dislikeCount,
          messages: chat.messages,
          lastAccessed: new Date(chat.lastAccessed).getTime(),
          averageResponseTime: chat.averageResponseTime,
          totalConversationDuration: chat.totalConversationDuration,
          errorRate: chat.errorRate,
        };
        await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/store_messages`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + this.token, // Replace <JWT-token> with your actual JWT token
            },
          }
        );
        console.log("chat saved");
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    },
    findChat(id) {
      return this.chats.find((chat) => chat.id === id);
    },
    async renameChat(chat) {
      if (chat.messages.length > 0) {
        try {
          const payload = {
            uuid: chat.id,
            chat_name: chat.name,
          };
          const response = await axios.post(
            `${import.meta.env.VITE_API_ENDPOINT}/api/users/rename_chat`,
            payload,
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          );
          this.selectedChat.name = chat.name;
          console.log(response.data);
          const chatToUpdate = this.chats.find((chat1) => chat1.id === chat.id);
          if (chatToUpdate) {
            chatToUpdate.name = chat.name;
          }
        } catch (error) {
          // Handle any errors
          console.error(error);
        }
      }
    },
    async deleteChat(uuid) {
      console.log(uuid);
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/delete_chat`,
          { chat_id: uuid },
          {
            headers: {
              Authorization: "Bearer " + this.token, // Replace <token> with your actual JWT token
            },
          }
        );
        this.chats = this.chats.filter((chat) => chat.id !== uuid);
        this.loadChat(uuidv4());
        // Handle the response data
        const { message } = response.data;
        console.log("deleted chat", message);
      } catch (error) {
        // Handle any errors
        console.error("An error occurred:", error);
      }
    },
    async loadChat(id) {
      console.log(this.chats[this.chats.length - 1]);
      const chat = this.findChat(id);
      if (chat) {
        console.log(chat);
        const messages = await this.retrieveSpecificChat(id);
        this.selectedChat = { ...messages, ...chat };
        console.log("messages recieved", messages, this.selectedChat);
        // } else if (!messages && this.isAuth) {
        //   this.createNewChat(id);
        //   console.log("New Chat");
        console.log(this.selectedChat);
      } else {
        this.createNewChat(uuidv4());
      }

      // this.chats = this.chats.filter((selectedChat) => selectedChat.id !== id);
    },

    fetch(sampleDataKey) {
      axios
        .get(`data-sources/${sampleDataKey}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            this[sampleDataKey] = r.data.data;
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    addQuestions(recievedQuestions) {
      const questions = [
        ...recievedQuestions,
        { data: [], sport: "Tennis", comming: true, text: "Coming Soon" },
      ];
      this.questions = questions.map((question) => {
        return this.addSportIcon(question);
      });
    },
    addSportIcon(sport) {
      const sportName = sport.sport.toLowerCase().replace(/\s+/g, ""); // Convert to lowercase and remove spaces
      const img = sports.find((sport) => sport.sport === sportName)?.path; // Using optional chaining to handle cases where sports.find returns undefined
      if (img) {
        return { ...sport, img: img };
      } else {
        console.log("Image not found for the sport.");
      }
    },
    handleLogout() {
      // Clear all user-related data from the state
      this.userName = null;
      this.userEmail = null;
      this.chat_access = false;
      this.isAuth = false;
      this.token = null;
      this.userIp = null;
      this.chats = [];
      this.selectedChat = {
        id: uuidv4(),
        name: "New chat",
        lastAccessed: new Date(),
        likeCount: 0,
        dislikeCount: 0,
        averageResponseTime: 0,
        totalConversationDuration: 0,
        errorRate: 0,
        messages: [],
      };
      (this.upcoming = []), router.push("/upcoming");
    },
    updateChats(responseData) {
      console.log("chats", responseData);
      if (responseData) {
        this.chats =
          responseData.chats.length >= 1 ? [...responseData.chats] : [];
        this.chats = this.chats.filter(
          (chat) => chat.id !== null && chat.id !== undefined && chat.id !== ""
        );

        if (this.chats.some((chat) => chat.id === this.selectedChat.id)) {
          this.loadChat(this.selectedChat.id);
        } else {
          this.createNewChat();
        }
      }
    },
    async getLoadingMessages() {
      if (this.isAuth) {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_ENDPOINT}/api/users/get_preloads`,
            null,
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          );

          // Do something with the retrieved chats
          console.log(response.data);
          this.loadingMessages = response.data;
        } catch (error) {
          this.userAvatar = this.userAvatar || getRandomAvatar();
          // Handle any errors~
          console.error(error);
        }
      }
    },
    async getUserAvatar() {
      if (this.isAuth) {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_ENDPOINT}/api/users/get_avatar`,
            null,
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          );

          // Do something with the retrieved chats
          console.log(response.data);

          this.userAvatar = response.data.avatar || getRandomAvatar();
          if (!response.data.avatar) {
            this.setUserAvatar(this.userAvatar);
          }
        } catch (error) {
          this.userAvatar = this.userAvatar || getRandomAvatar();
          // Handle any errors
          console.error(error);
        }
      }
    },
    async setUserAvatar(icon_name) {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_ENDPOINT}/api/users/set_avatar`,
          { icon_name: icon_name },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    },
    async fetchUserIp() {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        this.userIp = response.data.ip;
      } catch (error) {
        console.error("Error fetching user IP:", error);
        // Handle errors as needed
      }
    },
    async getQuestions() {
      try {
        const response = await axios.post(
          `${
            import.meta.env.VITE_API_ENDPOINT
          }/api/questions/get_all_prediction`
        );
        const questions = response.data;
        console.log(questions);
        this.addQuestions(questions);
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    },

    async retrieveChats() {
      if (this.token) {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_ENDPOINT}/api/users/all_chats`,
            null,
            {
              headers: {
                Authorization: "Bearer " + this.token, // Replace <JWT-token> with your actual JWT token
              },
            }
          );
          this.updateChats(response.data);
          return response.data.chats.length > 0;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // addMessageToArray("Session timeout.", "chatbot");
            console.error("session timeout");
            this.handleLogout();
          }
          // Handle any errors
          console.error(error);
        }
      }
    },
    async retrieveSpecificChat(id) {
      if (this.token) {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_ENDPOINT}/api/users/retrieve_chat`,
            {
              chat_id: id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token, // Replace <JWT-token> with your actual JWT token
              },
            }
          );
          return response.data;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // addMessageToArray("Session timeout.", "chatbot");
            console.error("session timeout");
            router.push({ name: "login" });
            this.handleLogout();
          }
          // Handle any errors
          console.error(error);
          return false;
        }
      }
      // else{
      // console.log("chatnotfound")
      //   return  {
      //     id: "1",
      //     name: "1",
      //     lastAccessed: 0,
      //     likeCount: 0,
      //     dislikeCount: 0,
      //     averageResponseTime: 0,
      //     totalConversationDuration: 0,
      //     errorRate: 0,
      //     messages: [],
      //     }
      // }
    },
    async fetchUpcoming() {
      console.log("fetching stats");
      if (this.upcoming.length > 0) {
        return;
      }
      const response = await axios.post(
        `${import.meta.env.VITE_API_ENDPOINT}/api/dashboard/get_all`,
        null,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      console.log("upcomming", response.data);
      this.upcoming = response.data;
    },
    async fetchExpress() {
      console.log("fetching stats");
      if (this.express.length > 0) {
        return;
      }
      const response = await axios.post(
        `${import.meta.env.VITE_API_ENDPOINT}/api/dashboard/express`,
        null,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      console.log("express", response.data);
      this.express = response.data;
    },
    async fetchRoi() {
      console.log("fetching stats");
      if (this.roi.length > 0) {
        return;
      }
      const response = await axios.post(
        `${import.meta.env.VITE_API_ENDPOINT}/api/dashboard/roi`,
        null,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      console.log("roi", response.data);
      this.roi = response.data;
    },
    addNotifications(messages, status = "success") {
      this.notifications.length = 0;
      messages.forEach((message) => {
        this.notifications.push({
          message: message,
          status: status,
        });
      });
    },
    checkExpirationAndUpdate() {
      // Get the current date and time
      let now = new Date();

      // Try to get the expiration date from localStorage
      let expiresAt = localStorage.getItem("expiresAt");
      let expiresAtdate = new Date(expiresAt);
      // If there is no expiration date, set it to 1 day from now
      if (!expiresAt || now > expiresAtdate) {
        this.upcoming = [];
        this.express = [];
        this.roi = [];
        expiresAt = new Date(now.getTime() + 60 * 60 * 1000); // 1 hr from now
        localStorage.setItem("expiresAt", expiresAt.toString());
      } else {
        // Convert the expiration date back into a Date object
        expiresAt = new Date(expiresAt);
        console.log("all goochie");
      }
    },
    setDeviceType() {
      const platform = navigator.platform.toLowerCase();
      if (
        /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(
          platform
        )
      ) {
        this.deviceType = "mobile";
      } else if (/mac|win|linux/i.test(platform)) {
        this.deviceType = "desktop";
      } else if (/tablet|ipad/i.test(platform)) {
        this.deviceType = "tablet";
      } else {
        this.deviceType = "unknown";
      }
    },
  },
  persist: {
    enabled: true, // Enable persisting the store state
    key: "mainStore", // Key for the persisted data in storage
    strategies: [
      {
        storage: localStorage,
        paths: [
          "userName",
          "userAvatar",
          "userEmail",
          "isAuth",
          "token",
          "chats",
          "upcoming",
          "express",
          "roi",
          "chat_access",
          "roulette_access",
        ],
      },
      {
        storage: sessionStorage,
        paths: ["selectedChat"],
      },
    ],
  },
});

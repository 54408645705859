export default {
  toast: {
    root: ({ props: e }) => ({
      class: [
        "w-80 md:w-96 rounded-md mt-12",
        {
          "-translate-x-2/4":
            e.position == "top-center" || e.position == "bottom-center",
        },
      ],
    }),
    container: ({ props: e }) => ({
      class: [
        "my-4 rounded-md w-full",
        "shadow-lg",
        "bg-surface-0 dark:bg-surface-800",
        {
          "text-blue-500 dark:text-blue-300": e.message.severity == "info",
          "text-white dark:text-white bg-green-600 opacity-90":
            e.message.severity == "success",
          "text-orange-500 dark:text-orange-300 bg-red-500 opacity-90":
            e.message.severity == "warn", // Updated line
          "text-white dark:text-white bg-red-600 opacity-90":
            e.message.severity == "error",
        },
      ],
    }),
    content: {
      class: "flex items-start p-4",
    },
    icon: {
      class: ["w-5 h-5", "mr-2 shrink-0"],
    },
    text: {
      class: ["text-sm leading-none", "ml-2", "flex-1"],
    },
    summary: {
      class: "font-medium block",
    },
    detail: {
      class: "mt-1.5 block text-surface-600 dark:text-surface-0/70",
    },
    closebutton: {
      class: [
        "flex items-center justify-center",
        "w-6 h-6",
        "ml-auto relative",
        "rounded-full",
        "bg-transparent",
        "text-surface-700 dark:text-surface-0/80",
        "transition duration-200 ease-in-out",
        "hover:bg-surface-100 dark:hover:bg-surface-700",
        "outline-none focus:ring-1 focus:ring-inset",
        "focus:ring-primary-500 dark:focus:ring-primary-400",
        "overflow-hidden",
      ],
    },
    closeicon: {
      class: ["w-3 h-3", "shrink-0"],
    },
    transition: {
      enterFromClass: "opacity-0 translate-y-2/4",
      enterActiveClass: "transition-[transform,opacity] duration-300",
      leaveFromClass: "max-h-[1000px]",
      leaveActiveClass:
        "!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s] overflow-hidden",
      leaveToClass: "max-h-0 opacity-0 mb-0",
    },
  },

  progressbar: {
    root: {
      class: [
        "overflow-hidden",
        "relative",
        "border-0",
        "h-6",
        "rounded-full",
        "bg-gray-500 bg-opacity-20",
      ],
    },
    value: ({ props }) => ({
      class: [
        // Flexbox & Overflow & Position
        {
          "absolute flex items-center justify-center overflow-hidden":
            props.mode !== "indeterminate",
        },
        // Colors
        "bg-green-600 dark:bg-green-600 rounded-full",
        // Spacing & Sizing
        "m-0",
        { "h-full w-0": props.mode !== "indeterminate" },
        // Shape
        "border-0",
        // Transitions
        {
          "transition-width duration-1000 ease-in-out":
            props.mode !== "indeterminate",
          "progressbar-value-animate": props.mode == "indeterminate",
        },
        // Before & After (indeterminate)
        {
          "before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-inherit ":
            props.mode == "indeterminate",
          "after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000":
            props.mode == "indeterminate",
        },
      ],
    }),
    label: {
      class: ["inline-flex", "text-white dark:text-surface-900", "leading-6"],
    },
  },
  directives: {
    tooltip: {
      root: ({ context }) => ({
        class: [
          // Position and Shadows
          "absolute",
          "shadow-md",
          "p-fadein",
          // Spacing
          {
            "py-0":
              (context == null ? void 0 : context.right) ||
              (context == null ? void 0 : context.left) ||
              (!(context != null && context.right) &&
                !(context != null && context.left) &&
                !(context != null && context.top) &&
                !(context != null && context.bottom)),
            " px-0":
              (context == null ? void 0 : context.top) ||
              (context == null ? void 0 : context.bottom),
          },
        ],
      }),
      arrow: ({ context }) => ({
        class: [
          // Position
          "absolute",
          // Size
          "w-0",
          "h-0",
          // Shape
          "border",
          "border-solid",
          {
            "border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-gray-200":
              (context == null ? void 0 : context.right) ||
              (!(context != null && context.right) &&
                !(context != null && context.left) &&
                !(context != null && context.top) &&
                !(context != null && context.bottom)),
            "border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-gray-200":
              context == null ? void 0 : context.left,
            "border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-gray-200":
              context == null ? void 0 : context.top,
            "border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-gray-200":
              context == null ? void 0 : context.bottom,
          },
          // Spacing
          {
            "-mt-1 ":
              (context == null ? void 0 : context.right) ||
              (!(context != null && context.right) &&
                !(context != null && context.left) &&
                !(context != null && context.top) &&
                !(context != null && context.bottom)),
            "-mt-1": context == null ? void 0 : context.left,
            "-ml-1":
              (context == null ? void 0 : context.top) ||
              (context == null ? void 0 : context.bottom),
          },
        ],
      }),
      text: {
        class: [
          "p-3",
          "bg-white dark:bg-slate-700",
          "text-white",
          "leading-none",
          "rounded-md",
          "whitespace-pre-line",
          "break-words",
        ],
      },
    },
    ripple: {
      root: {
        class: ["block absolute bg-white/50 rounded-full pointer-events-none"],
        style: "transform: scale(0.1)",
      },
    },
  },
  skeleton: {
    root: ({ props }) => ({
      class: [
        "overflow-hidden",
        {
          "animate-pulse": props.animation !== "none",
        },
        // Round
        {
          "rounded-full": props.shape === "circle",
          "rounded-md": props.shape !== "circle",
        },
        // Colors
        "bg-gray-200 dark:bg-gray-700",
      ],
    }),
  },
};

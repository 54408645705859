import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import App from "./App.vue";
import router from "./router";
import { useStyleStore } from "@/stores/style.js";
import { useMainStore } from "./stores/main";
import { darkModeKey, styleKey } from "@/config.js";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import firebaseConfig from "./firebaseConfig.js";
import "./css/main.css";
import axios from "axios";
import VueMatomo from "vue-matomo";
import VueSmoothScroll from "vue3-smooth-scroll";
import { VueReCaptcha } from "vue-recaptcha-v3";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import ToastService from "primevue/toastservice";
import Wind from "@/presets/wind";
import * as Sentry from "@sentry/vue";
import { plugin as vueTransitionsPlugin } from "@morev/vue-transitions";
import Ripple from "primevue/ripple";
import "@morev/vue-transitions/styles";

// import Tooltip from 'primevue/tooltip';

const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://6a4193d30eb97f8a2b65994435b03134@o4506706566512640.ingest.sentry.io/4506706569854976",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/chatftw\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
app.use(VueMatomo, {
  host: "https://dashboard.chatftw.com",
  siteId: 1,
  trackerFileName: "ftw",
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  localStorageName: "matomo",
  localStorageExpire: 365,
  localStorageExpireDays: 365,
  localStorageExpireHours: 24,
  localStorageExpireMinutes: 60,
  localStorageExpireSeconds: 60,
  localStoragePrefix: "matomo",
});
app.use(PrimeVue, {
  unstyled: true,
  pt: Wind,
  ripple: true,
});
app.directive("ripple", Ripple);

app.use(ToastService);

app.use(
  vueTransitionsPlugin({
    defaultProps: {
      duration: 300,
    },
  })
);
/* Init Pinia */
const pinia = createPinia();
pinia.use(piniaPersist);
app.use(pinia).use(VueSmoothScroll);
// app.use(VueRecaptchaPlugin, { v2SiteKey: '' });
app.use(VueReCaptcha, { siteKey: "6LdeRTQpAAAAAGOIB12W-sWtWTGD2rQyayfTjh3Q" });
const styleStore = useStyleStore();
const mainStore = useMainStore();
mainStore.checkExpirationAndUpdate();
/* App style */
styleStore.setStyle(localStorage[styleKey] ?? "basic");

/* Dark mode */
if (
  (!localStorage[darkModeKey] &&
    window.matchMedia("(prefers-color-scheme: dark)").matches) ||
  localStorage[darkModeKey] === "1"
) {
  styleStore.setDarkMode(true);
}
function getTwitterClickID() {
  const urlParams = new URLSearchParams(window.location.search);
  const twclid = urlParams.get("twclid");
  return twclid;
}
const twclid = getTwitterClickID();
let date = new Date();
date.setFullYear(date.getFullYear() + 1); // set the expiry date to 1 year from now
document.cookie =
  "twclid=" + twclid + "; expires=" + date.toUTCString() + "; path=/";

axios.defaults.timeout = 100000;

mainStore.fetchUserIp();
// mainStore.fetchUpcoming();
mainStore.getUserAvatar();
mainStore.setDeviceType();
mainStore.getLoadingMessages();

window.addEventListener("storage", (event) => {
  if (event.key === "main") {
    const mainObject = JSON.parse(localStorage.getItem("main"));
    console.log("main", mainObject);
    mainStore.$patch(mainObject);
    if (mainObject?.token == null) {
      mainStore.handleLogout();
    }
  }
});

firebase.initializeApp(firebaseConfig);
firebase.analytics();

/* Default title tag */
const defaultDocumentTitle = "ChatFTW";
router.afterEach((to) => {
  document.title = to.meta?.title
    ? `${to.meta.title} — ${defaultDocumentTitle}`
    : defaultDocumentTitle;
});
app.use(router).mount("#app");

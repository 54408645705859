<script setup>
import { watch, ref } from "vue";
import { RouterView, RouterLink, useRouter } from "vue-router";
import { useMainStore } from "@/stores/main"; // Import your Pinia store
import { useToast } from "primevue/usetoast";
import { storeToRefs } from "pinia";
import Toast from "primevue/toast";
import confetti from "canvas-confetti";
import Promise from "bluebird";
import HandleCache from "@/components/cache/HandleCache.vue";
const cacheBuster = ref();
const toast = useToast();
const mainStore = useMainStore();
const isAuthenticated = () => {
  return mainStore.isAuth;
};
const router = useRouter();
confetti.Promise = Promise;
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    // Redirect to the login page if the user is not authenticated and the route requires authentication
    next({ name: "login" });
  } else {
    const routeNames = ["login", "home", "signup"];
    if (routeNames.includes(to.name) && isAuthenticated()) {
      // Redirect to the "upcoming" route if the current route name is in the routeNames array
      next({ name: "upcoming" });
    } else {
      // Proceed to the next route
      console.log("Proceeding to the next route");
      next();
    }
  }
});
const { notifications } = storeToRefs(mainStore);
watch(
  () => notifications.value,
  (newVal, oldVal) => {
    console.log("Property changed from", oldVal, "to", newVal);

    if (Array.isArray(newVal)) {
      newVal.forEach((notification) => {
        setTimeout(() => {
          const severity =
            notification.status === "error" ? "error" : "success";
          console.log(severity, notification);
          toast.add({
            severity: severity,
            summary: "",
            group: "tr",
            detail: notification.message,
            life: 5000,
          });

          if (notification.status !== "error") {
            triggerConfetti();
          }
        }, 100);
      });
    } else {
      console.warn("Expected an array for newVal, but received:", newVal);
    }
  },
  { deep: true }
);

function triggerConfetti() {
  var end = Date.now() + 15 * 10;
  var colors = ["#bb0000", "#ffffff"];

  (function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
}
</script>

<template>
  <HandleCache ref="cacheBuster">
    <template v-if="isAuthenticated">
      <!-- Render the RouterView if the user is authenticated -->
      <RouterView />
    </template>
    <template v-else>
      <!-- Redirect the user to the login page if not authenticated -->
      <RouterLink to="/login">Sign in</RouterLink>
    </template>
    <Toast group="tr" />
  </HandleCache>
</template>

import { createRouter, createWebHistory } from "vue-router";

const isValidUUIDv4 = (uuid) => {
  const uuidv4Regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidv4Regex.test(uuid);
};
const routes = [
  {
    meta: {
      title: "Upcoming",
      requiresAuth: false,
    },
    path: "/upcoming",
    name: "upcoming",
    component: () => import("@/views/UpcomingView.vue"),
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/ChatView.vue"),
    props: (route) => ({
      id: route.query.id,
      event: route.query.event || "",
    }),
    beforeEnter: (to, from, next) => {
      const { id } = to.query;
      if (id === "newChat" || (id && !isValidUUIDv4(id))) {
        next({ path: "/", query: { ...to.query, id: undefined } });
      } else {
        next();
      }
    },
  },
  {
    path: "/logout",
    name: "logout",
    redirect: () => {
      return { path: `/` };
    },
  },
  {
    meta: {
      title: "Login",
      requiresAuth: false,
    },
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    props: (route) => ({
      id: route.query.id,
      registered: route.query.registered || "",
    }),
  },
  {
    meta: {
      title: "Sign Up",
      requiresAuth: false,
    },
    path: "/signup",
    name: "signup",
    component: () => import("@/views/RegisterView.vue"),
    props: (route) => ({
      id: route.query.id,
    }),
  },
  {
    meta: {
      title: "Forgot Password",
      requiresAuth: false,
    },
    path: "/forgot-password",
    name: "forgotPass",
    component: () => import("@/views/ForgotPass.vue"),
  },
  {
    meta: {
      title: "Reset Password",
      requiresAuth: false,
    },
    path: "/reset-password",
    name: "resetPass",
    component: () => import("@/views/ResetPass.vue"),
    props: (route) => ({
      token: route.query.token || "",
    }),
  },
  {
    meta: {
      title: "Soon",
      requiresAuth: false,
    },
    path: "/soon",
    name: "commingSoon",
    component: () => import("@/views/CommingSoon.vue"),
  },
  {
    meta: {
      title: "Terms Of Service",
      requiresAuth: false,
    },
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () => import("@/views/TermsOfServiceView.vue"),
  },
  {
    meta: {
      title: "Privacy Policy",
      requiresAuth: false,
    },
    path: "/privacy-policy",
    name: "PrivacyPolicyView",
    component: () => import("@/views/PrivacyPolicyView.vue"),
  },
  {
    meta: {
      title: "About this version",
      requiresAuth: false,
    },
    path: "/about-version",
    name: "AboutVersionView",
    component: () => import("@/views/VersionView.vue"),
  },
  {
    meta: {
      title: "Feedback",
      requiresAuth: false,
    },
    path: "/feedback",
    name: "feedback",
    component: () => import("@/views/FeedbackView.vue"),
  },
  {
    meta: {
      title: "Feedback List",
      requiresAuth: true,
    },
    path: "/feedbacklist",
    name: "Feedback List",
    component: () => import("@/views/FeedBackList.vue"),
  },

  {
    meta: {
      title: "Profile",
      requiresAuth: true,
    },
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfileView.vue"),
  },

  {
    meta: {
      title: "Roulette",
      requiresAuth: true,
    },
    name: "roulette",
    path: "/roulette",
    component: () => import("@/views/RouletteView.vue"),
  },
  {
    meta: {
      title: "Error",
      requiresAuth: false,
    },
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorView.vue"),
  },
  {
    meta: {
      title: "Leaderboard",
      requiresAuth: true,
    },
    path: "/Leaderboard",
    name: "leaderboard",
    component: () => import("@/views/LeaderBoardView.vue"),
  },
  {
    meta: {
      title: "Express Bets",
      requiresAuth: true,
    },
    path: "/express",
    name: "express",
    component: () => import("@/views/ExpressView.vue"),
  },
  {
    meta: {
      title: "ROI History",
      requiresAuth: true,
    },
    path: "/history",
    name: "history",
    component: () => import("@/views/HistoryView.vue"),
  },
  {
    meta: {
      title: "Home",
    },
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/:catchAll(.*)", // Use a parameter with a custom regexp to define the catch-all route
    redirect: "/error",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      return savedPosition || { top: 0 };
    }
  },
});
export default router;

export const avatars = [
  { link: "Asset 10.svg", title: "1" },
  { link: "Asset 11.svg", title: "1" },
  { link: "Asset 12.svg", title: "1" },
  { link: "Asset 13.svg", title: "1" },
  { link: "Asset 14.svg", title: "1" },
  { link: "Asset 15.svg", title: "1" },
  { link: "Asset 16.svg", title: "1" },
  { link: "Asset 17.svg", title: "" },
  { link: "Asset 18.svg", title: "" },
  { link: "Asset 19.svg", title: "" },
  { link: "Asset 2.svg", title: "" },
  { link: "Asset 20.svg", title: "" },
  { link: "Asset 21.svg", title: "" },
  { link: "Asset 22.svg", title: "" },
  { link: "Asset 23.svg", title: "" },
  { link: "Asset 24.svg", title: "" },
  { link: "Asset 25.svg", title: "" },
  { link: "Asset 26.svg", title: "" },
  { link: "Asset 27.svg", title: "" },
  { link: "Asset 28.svg", title: "" },
  { link: "Asset 29.svg", title: "" },
  { link: "Asset 3.svg", title: "" },
  { link: "Asset 30.svg", title: "" },
  { link: "Asset 31.svg", title: "" },
  { link: "Asset 32.svg", title: "" },
  { link: "Asset 33.svg", title: "" },
  { link: "Asset 34.svg", title: "" },
  { link: "Asset 35.svg", title: "" },
  { link: "Asset 36.svg", title: "" },
  { link: "Asset 37.svg", title: "" },
  { link: "Asset 38.svg", title: "" },
  { link: "Asset 39.svg", title: "" },
  { link: "Asset 4.svg", title: "" },
  { link: "Asset 40.svg", title: "" },
  { link: "Asset 41.svg", title: "" },
  { link: "Asset 42.svg", title: "" },
  { link: "Asset 43.svg", title: "" },
  { link: "Asset 44.svg", title: "" },
  { link: "Asset 45.svg", title: "" },
  { link: "Asset 46.svg", title: "" },
  { link: "Asset 5.svg", title: "" },
  { link: "Asset 6.svg", title: "" },
  { link: "Asset 7.svg", title: "" },
  { link: "Asset 8.svg", title: "" },
  { link: "Asset 9.svg", title: "" },
];

export default function getRandomAvatar() {
  const randomIndex = Math.floor(Math.random() * avatars.length);
  const avatar = avatars[randomIndex];

  return avatar.link;
}
